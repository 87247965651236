import _ from "lodash";

class MailService {
  constructor() {
    this.baseUrl = "https://europe-west1-afb-connect.cloudfunctions.net";
  }

  sendContactRequest(body) {
    const parseBody = {
      ...body,
      ...(body.type ? {} : { type: "contact" }),
    };
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.open("POST", `${this.baseUrl}/registerUserContact`, true);
      this.requestCall(xhttp, parseBody, resolve, reject);
    });
  }

  async sendMail(body, query) {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.open("POST", `${this.baseUrl}/sendgrid-cloud-function/send-mail?${new URLSearchParams(query).toString()}`, true);
      this.requestCall(xhttp, body, resolve, reject);
    });
  }

  requestCall(xhttp, body, resolve, reject) {
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.onload = function () {
      if (xhttp.status >= 200 && xhttp.status < 300) {
        resolve(JSON.parse(xhttp.response));
      } else {
        reject({
          status: xhttp.status,
          statusText: xhttp.statusText,
        });
      }
    };
    xhttp.onerror = function () {
      reject({
        status: xhttp.status,
        statusText: xhttp.statusText,
      });
    };
    xhttp.send(JSON.stringify(body));
  }

  validate = (requiredInputs, data) => {
    const errors = {};
    requiredInputs.map(({ value, label }) => {
      if (!data[value] || _.isEmpty(data[value])) errors[value] = `${label.props?.id || label} is required`;
      return null;
    });
    return _.isEmpty(errors);
  };
}

const Mail = new MailService();
export default Mail;
