import React from "react";
import { Button, CircularProgress } from "@mui/material";

function LoadingButton({ loading, children, ...rest }) {
  return (
    <Button
      {...rest}
      sx={{
        ...rest.sx,
        "*": {
          color: "white !important",
        },
      }}
    >
      {loading ? (
        <CircularProgress
          style={{
            width: "20px",
            height: "20px",
          }}
          color="secondary"
        />
      ) : (
        children
      )}
    </Button>
  );
}

export default LoadingButton;
