import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import React from "react";
import Reaptcha from "reaptcha";
import Mail from "../services/MailService";
import Checkbox from "./Checkbox";
import LoadingButton from "./LoadingButton";
import Modal from "./Modal";

const inputs = () => [
  { value: "name", label: <Trans>Full name</Trans> },
  { value: "email", label: <Trans>Work Email</Trans> },
  { value: "phone", label: <Trans>Phone number</Trans> },
];

function ModalTouch({ onClose }) {
  const [data, setData] = React.useState({ type: "demo" });
  const [loading, setLoading] = React.useState(false);
  const { locale } = useLocalization();

  const sendContact = () => {
    setLoading(true);
    Mail.sendContactRequest(data).then(({ status }) => {
      if (status === 200) {
        setLoading(false);
        onClose();
      }
    });
  };

  return (
    <Modal
      open
      onClose={onClose}
      backgroundColor="white"
      sx={{ width: "650px !important" }}
    >
      <Typography variant="h2">
        <Trans>Interested? Get in touch! </Trans>
      </Typography>
      <Typography my={1}>
        <Trans>
          Please fill out the form below and a specialist will get back to you
          as soon as possible.
        </Trans>
      </Typography>
      <Grid container spacing={1}>
        {inputs().map(({ value, label }) => (
          <Grid item xs={12}>
            <TextField
              label={label}
              variant="outlined"
              fullWidth
              onChange={(e) => setData({ ...data, [value]: e.target.value })}
              value={data[value]}
              required
              disabled={loading}
            />
          </Grid>
        ))}
      </Grid>
      <Stack mt={2} spacing={2} sx={{ fontStyle: "italic" }}>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={data.checkbox_terms}
            onChange={() =>
              setData({ ...data, checkbox_terms: !data?.checkbox_terms })}
          >
            <Trans>
              I have read and accepted Allfunds general terms of use within the{" "}
              <LocalizedLink to="/legal-notice">Legal Notice</LocalizedLink>{" "}
              section
            </Trans>
          </Checkbox>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={data.checkbox_policy}
            onChange={() =>
              setData({ ...data, checkbox_policy: !data?.checkbox_policy })}
          >
            <Trans>
              I have read and accepted{" "}
              <LocalizedLink to="/privacy-policy">
                Allfunds Privacy Policy
              </LocalizedLink>
            </Trans>
          </Checkbox>
        </Box>
        <Box>
          <Reaptcha
            hl={locale}
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onVerify={(token) =>
              setData({ ...data, "g-recaptcha-response": token })}
          />
        </Box>
        <Box>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={
              !data.checkbox_terms ||
              !data.checkbox_policy ||
              !data["g-recaptcha-response"] ||
              !Mail.validate(inputs(), data)
            }
            onClick={() => sendContact()}
            loading={loading}
          >
            <Typography>
              <Trans>Send</Trans>
            </Typography>
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  );
}

export default ModalTouch;
