import {
  faArrowsToCircle,
  faRocketLaunch,
  faWindow,
} from "@fortawesome/pro-light-svg-icons";
import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowButton from "../../components/ArrowButton";
import Container from "../../components/Container";
import CustomizedIcon from "../../components/CustomizedIcon";
import Hero from "../../components/Hero";
import ModalTouch from "../../components/ModalTouch";
import NewLayout from "../../components/NewLayout";
import contractImage from "../../images/distributors/alternatives/contract.png";
import dataImage from "../../images/distributors/alternatives/data.png";
import operationsImage from "../../images/distributors/alternatives/operations.png";
import privatePartners from "../../images/fund-houses/private_partners.png";
import heroImage from "../../images/headers/alternatives_distributors.png";

const SOLUTIONS = [
  {
    icon: faArrowsToCircle,
    color: "#D50032",
    description: (
      <Trans>
        Stand alone department of 40+ professionals focused solely on
        alternative and private markets funds
      </Trans>
    ),
  },
  {
    icon: faRocketLaunch,
    color: "#1B365D",
    description: (
      <Trans>
        Tech enhancements of current platform to facilitate distribution of
        closed-ended typical drawdown funds and open-ended semi liquid funds.
      </Trans>
    ),
  },
  {
    icon: faWindow,
    color: "#5AB574",
    description: (
      <Trans>
        Handling of Pre trading, Trading, Post Trading: Client Onboarding,
        Commitments and Capital calls Management, Reports, Defaults
      </Trans>
    ),
  },
];

const DATA = [
  {
    title: <Trans>Contract and fund offering</Trans>,
    image: contractImage,
    subItems: [
      <Trans>
        One global distribution agreement to get access to +1000 funds from 100
        Alternative AMs.
      </Trans>,
      <Trans>Buy Free Model, our key to success.</Trans>,
      <Trans>Instant access to global fund universe.</Trans>,
    ],
  },
  {
    title: <Trans>Operations</Trans>,
    image: operationsImage,
    subItems: [
      <Trans>
        Single Operational Set up: Trading, settlement & core services.
      </Trans>,
      <Trans>
        Connectivity via SWIFT, FTP, API, Web (Connect) for both traditional and
        alternative funds.
      </Trans>,
      <Trans>Fully Digitalised and Automated Trading Process.</Trans>,
    ],
  },
  {
    title: <Trans>Data and Tech Solution</Trans>,
    image: dataImage,
    subItems: [
      <Trans>Transparency & regulatory reporting.</Trans>,
      <Trans>
        Data & digital solutions: Access to Data and Fund Documentation,
        Corporate Actions, Historical NAVs.
      </Trans>,
      <Trans>
        Fund Insight / Educational portal: access educational material through
        our Connect portal.
      </Trans>,
    ],
  },
];

function DistributorsAlternativesPage() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <NewLayout>
        <Hero
          kind={<Trans>Distributors</Trans>}
          title={<Trans>Allfunds Alternative Solutions</Trans>}
          description={
            <Trans>
              Allfunds offers a fully integrated solutions for Distributors and
              for Alternative Asset Managers to access and onboard private
              Market funds.
            </Trans>
          }
          backgroundImage={heroImage}
        />
        <Container py={{ xs: 4, md: 8 }}>
          <Stack spacing={2}>
            <Typography variant="h3" mb={2}>
              <Trans>Why Allfunds Alternative Solutions</Trans>
            </Typography>
            <Box>
              <Grid container spacing={2}>
                {SOLUTIONS.map(({ icon, color, description }) => (
                  <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                      <CustomizedIcon
                        size={36}
                        padding={1.5}
                        icon={icon}
                        color={color}
                        sx={{ margin: { xs: "0 auto", md: 0 } }}
                      />
                      <Typography mt={2}>{description}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box
              textAlign="center"
              sx={{ marginTop: (theme) => `${theme.spacing(4)} !important` }}
            >
              <ArrowButton
                isDark
                onClick={() => setShowModal(true)}
                title={<Trans>Get in touch</Trans>}
              />
            </Box>
          </Stack>
        </Container>
        <Box py={6}>
          <Stack spacing={2} textAlign="center">
            <Typography variant="h4" color="secondary.main">
              <Trans>Distributors</Trans>
            </Typography>
            <Typography variant="h2">
              <Trans>Access Private Markets Funds</Trans>
            </Typography>
          </Stack>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
            py={6}
            mt={14}
          >
            <Box mt={-20}>
              <Container>
                <Grid container spacing={4}>
                  {DATA.map(({ title, image, subItems }) => (
                    <Grid item xs={12} md={4}>
                      <Box textAlign="center">
                        <img src={image} alt={title} />
                      </Box>
                      <Typography color="white" variant="h4" mt={2}>
                        {title}
                      </Typography>
                      <ul
                        style={{
                          display: "grid",
                          gap: "24px",
                        }}
                      >
                        {subItems.map((item) => (
                          <li style={{ color: "white" }}>{item}</li>
                        ))}
                      </ul>
                    </Grid>
                  ))}
                  <Grid item xs={12} textAlign="center">
                    <ArrowButton
                      onClick={() => setShowModal(true)}
                      title={<Trans>Get in touch</Trans>}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </Box>
        <Container py={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} textAlign="center">
              <img
                src={privatePartners}
                alt="private_partners"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography
                    variant="text"
                    color="secondary.main"
                    fontWeight={600}
                  >
                    APP
                  </Typography>
                  <Typography variant="h2">
                    <Trans>Allfunds Private Partners programme</Trans>
                  </Typography>
                </Box>

                <ul
                  style={{
                    display: "grid",
                    gap: "24px",
                  }}
                >
                  <li>
                    <Trans>
                      Allfunds Private Partners programme designed for a
                      selected number of alternative asset managers that have
                      demonstrated their capabilities to create adapted products
                      to the wealth management segment
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      The partnership is based on providing increased visibility
                      in front of our distribution network.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Our partners will be able to participate in our events,
                      roundtables and put forward their educational material.
                    </Trans>
                  </li>
                </ul>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </NewLayout>
      {showModal && <ModalTouch onClose={() => setShowModal(false)} />}
    </>
  );
}

export default DistributorsAlternativesPage;
